<template>
  <v-container>
    <back-toolbar :title="$t('myPocoNavigation.preferences')"></back-toolbar>
    <v-row class="px-2">
      <v-col cols="12" class="px-1">
        <v-btn rounded height="32" class="gradient white--text" block>{{
          $t("myPoco.like")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row style="max-width: 100%">
      <v-col cols="12">
        <v-btn
          v-for="category in prefCategories"
          :key="category.title"
          class="filter-categories Heading-White-H6-14-Center"
          :ripple="false"
          :class="
            findIndex($store.state.profile.data.likes, (o) => {
              return o.id === category.id;
            }) >= 0
              ? 'white--text'
              : ''
          "
          @click="toggleCategory(category)"
          :style="
            findIndex($store.state.profile.data.likes, (o) => {
              return o.id === category.id;
            }) >= 0
              ? { 'background-color': `${category.color}` }
              : !$store.state.settings.dark
              ? 'background-color:#fff; color: #000;'
              : ''
          "
          >{{ $t(`categories.${category.title}`)
          }}<v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import findIndex from "lodash/findIndex";
import { mapState } from "vuex";

export default {
  data: () => ({
    toggledCategory: false,
  }),
  components: {
    BackToolbar,
  },
  computed: {
    ...mapState({
      categories: ({ categories }) => categories.categories,
    }),
    prefCategories() {
      return this.categories.filter(
        (c) => c.title !== "Mobility" && c.title !== "Essentials"
      );
    },
    likes: {
      get() {
        return this.$store.state.profile.data.likes || [];
      },
      set(val) {
        this.$store.commit("profile/SET_LIKES", val);
      },
    },
  },
  methods: {
    findIndex,
    toggleCategory(category) {
      this.toggledCategory = true;
      let index = findIndex(this.$store.state.profile.data.likes, (o) => {
        return o.id === category.id;
      });

      if (index >= 0) {
        this.removeLike(index);
      } else {
        this.likes.push(category);
      }
    },
    removeLike(index) {
      this.likes.splice(index, 1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.toggledCategory) {
      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    }
    next();
  },
};
</script>
<style>
.filter-categories {
  border-radius: 12px;
  display: inline-block;
  margin: 0px 8px 15px 2px;
}
.filter-items {
  max-height: calc(calc(100vh - 200px) - env(safe-area-inset-top)) !important;
  overflow: auto !important;
}
</style>
