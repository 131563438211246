var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('back-toolbar',{attrs:{"title":_vm.$t('myPocoNavigation.preferences')}}),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"gradient white--text",attrs:{"rounded":"","height":"32","block":""}},[_vm._v(_vm._s(_vm.$t("myPoco.like")))])],1)],1),_c('v-divider'),_c('v-row',{staticStyle:{"max-width":"100%"}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.prefCategories),function(category){return _c('v-btn',{key:category.title,staticClass:"filter-categories Heading-White-H6-14-Center",class:_vm.findIndex(_vm.$store.state.profile.data.likes, function (o) {
            return o.id === category.id;
          }) >= 0
            ? 'white--text'
            : '',style:(_vm.findIndex(_vm.$store.state.profile.data.likes, function (o) {
            return o.id === category.id;
          }) >= 0
            ? { 'background-color': ("" + (category.color)) }
            : !_vm.$store.state.settings.dark
            ? 'background-color:#fff; color: #000;'
            : ''),attrs:{"ripple":false},on:{"click":function($event){return _vm.toggleCategory(category)}}},[_vm._v(_vm._s(_vm.$t(("categories." + (category.title))))),_c('v-icon',[_vm._v("mdi-plus")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }